<template>
  <div class="client-products-shopping-cart client-page">
    <ShoppingCartListTable :is-kind="true" :is-name="false" />
  </div>
</template>

<script>
import ShoppingCartListTable from "../products/components/ShoppingCartListTable";
export default {
  name: "ShoppingCart",
  components: {
    ShoppingCartListTable
  },
  data() {
    return {
      desc: "浏览商品-购物车"
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped></style>
